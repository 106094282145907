// console.log( 'Home page!' );
jQuery(document).ready(function($){

  // slider for Featured products + SSP products (shortcode) on home page
  if (window.matchMedia("(max-width: 575px)").matches) {
    /* the viewport is less than 575 pixels wide */

    var prevBtn = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="far fa-angle-left"></i></button>';
    var nextBtn = '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="far fa-angle-right"></i></button>';

    if ( 1 < $('.products > .product').length ) {
      $('.products').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: true,
        prevArrow: prevBtn,
        nextArrow: nextBtn,
      });
    }
    if ( 1 < $('.ssp-items > .ssp-item').length ) {
      $('.ssp-items').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: true,
        prevArrow: prevBtn,
        nextArrow: nextBtn,
      });
    }
  }

});